@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  max-width: 1600px;
  margin: 0 auto;
  font-family: Space Grotesk;
}
/* Navbar */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  height: 120px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  position: fixed;
  z-index: 2;
  background-color: #000000;
  max-width: 1700px;
  margin: 0 auto;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
  background-color: #000000;
}

.container {
  z-index: 1;
  width: 100vw;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  width: 200px;
  height: auto;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-between;
}

.navbar.colorChange {
  background: rgba(111, 3, 137, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
}
.nav-item {
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.nav-links:hover {
  color: #0fe5f3;
  transform: scale(1.2);
  transition: all 0.3s ease;
}

.nav-item:hover {
  color: #0fe5f3;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.activated {
  color: #0fe5f3;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 120px;
    left: -100%;
    opacity: 1;

    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #0fe5f3;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 2;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #19191a;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
    color: #19191a;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: -50px;
    left: -50px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}

/* Home */
.display {
  margin-top: 120px;
  background-color: #000000;
  background-image: url("Assets/Images/bg-image.png");
}

.hero .hero-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: center;
}

.hero-sub {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.secondary-text {
  background: linear-gradient(180deg, #951150 26.56%, #0fe5f3 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.secondary-bg {
  background: linear-gradient(180deg, #951150 26.56%, #0fe5f3 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.btn {
  width: 160px;
  height: 40px;
  background: linear-gradient(
    180deg,
    #951150 14.06%,
    rgba(168, 65, 241, 0.2) 100%
  );
  box-shadow: 4px 4px 4px rgba(72, 45, 238, 0.76);
  border-radius: 10px;
}

/* About */
.about {
  background: rgba(30, 30, 30, 0.41);
  border: 1px solid rgba(15, 229, 243, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.about-head {
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.about-subheading {
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}

.about-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

/* Services */
.services-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: center;
}

.services-wrap {
  justify-content: space-between;
}

.services-icon {
  margin: 0 auto;
}

.services-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  display: inline-block;
}

@media screen and (max-width: 480px) {
  .services-icon {
    margin: 0 auto;
    width: 80px;
    height: auto;
  }

  .services-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    display: inline-block;
  }
}

/* Community */
.community {
  background: #0c0c0c;
  border: 1px solid rgba(15, 229, 243, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.community-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
}

.community-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin: 40px 25% 10px 25%;
  align-items: center;
}

.icon {
  font-size: 30px;
}

@media screen and (max-width: 600px) {
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin: 40px 10% 10px 10%;
    align-items: center;
  }
}

/* Team */
.team-heading {
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}

.team-wrap {
  justify-content: space-between;
}

.team-icon {
  margin: 0 auto;
}

.team-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
}
.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width: 480px) {
  .team-icon {
    margin: 0 auto;
    width: 80px;
    height: auto;
  }

  .team-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}

/* contact */
.contact {
  background: #0c0c0c;
  border: 1px solid rgba(15, 229, 243, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.contact-heading {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
}

.contain {
  margin: auto;
  justify-content: center;
  max-width: 800px;
}

.contact-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.message {
  border-radius: 6px;
  background: #fefcfc;
  padding: 10px;
  width: 50%;
}

.email {
  max-width: 300px;
}
